<template>
  <div id="app">
    <Navigation />
    <RouterView class="view" />
    <Footer />
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navigation,
    Footer
  }
}
</script>

<style lang="scss">
@import url('./assets/pure-min.css');
@import url('./assets/grids-responsive-min.css');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
  font-family: 'Varta', Helvetica, Arial, sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .view {
    margin-top: 80px;
  }
}
</style>
