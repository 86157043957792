<template>
  <div id="nav" v-bind:class="{scrolled: scrollPosition > 50}">
    <div class="logos">
      <img class="logo" src="../assets/logo.svg" />
      <img class="wordmark" src="../assets/wordmark.svg" />
    </div>
    <ul v-if="!isMobile">
      <li v-for="route in routes" :key="route.label">
        <router-link 
          :to="route.to" 
          class="link">{{route.label}}
        </router-link>
      </li>
    </ul>
    <button
      class="hamburger hamburger--criss-cross"
      v-if="isMobile"
      type="button"
      v-on="{click: toggleHamburger}"
      :class="{'active': isHamburgerActive}">
      <div class="inner">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
    </button>
    <div class="mobile" v-if="isMobile" :class="{scrolled: scrollPosition > 50, inactive: !isHamburgerActive}">
      <ul>
        <li v-for="route in routes" :key="route.label">
          <router-link 
            :to="route.to" 
            class="link">{{route.label}}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data: () => {
    return {
      scrollPosition: Number,
      isHamburgerActive: false,
      windowWidth: Number,
      routes: [
        {
          to: "/",
          label: "Início"
        },
        {
          to: "/sobre",
          label: "Sobre"
        },
        {
          to: "/contato",
          label: "Contato"
        },
      ]
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth < 650
    }
  },
  methods: {
    updateScroll () {
      this.scrollPosition = document.documentElement.scrollTop
    },
    updateWindowSize () {
      this.windowWidth = window.innerWidth
    },
    toggleHamburger () {
      this.isHamburgerActive = !this.isHamburgerActive;
    }
  },
  mounted () {
    this.updateScroll();
    this.updateWindowSize();
    window.addEventListener('scroll', this.updateScroll);
    window.addEventListener('resize', this.updateWindowSize)
  }
}
</script>

<style scoped lang="scss">
$hamburger-color:  #083453;
$hamburger-thickness: 2px;

@import "~delicious-hamburgers/scss/base";
@import "~delicious-hamburgers/scss/animations/criss-cross";

#nav {
  position: fixed;
  top: 0;
  height: 100%;
  max-height: 80px;
  width: 100%;
  background: #fff;
  z-index: 1;
  transition: 500ms all;

  &.scrolled {
    background: rgba(255, 255, 255, 0.90);
    max-height: 60px;
  }

  .hamburger {
    position: absolute;
    display: inline-block;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    .inner .bar {
      background: #083453;
    }
  }

  .logos {
    width: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    * {
      position: relative;
      margin-left: 20px;
      height: 100%;
      transition: 500ms max-height;
    }

    .logo {
      max-height: 60px;
    }

    .wordmark {
      max-height: 50px;
    }
  }

  &.scrolled {
    .logo {
      max-height: 40px;
    }

    .wordmark {
      max-height: 30px;
    }
  }

  ul {
    display: inline-block;
    position: absolute;
    width: auto;
    right: 0;
    margin: 0;
    padding-right: 30px;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;

    li {
      display: inline-block;
      margin: 0 10px;
      font-size: 20px;
      font-weight: 200;
    }

    .link {
      color: #083453;
      text-decoration: none;

      &.router-link-exact-active {
        font-weight: 800
      }
    }
  }

  .mobile {
    position: fixed;
    width: 100%;
    top: 80px;
    max-height: 500px;
    overflow: hidden;
    transition: 500ms all;

    &.inactive {
      max-height: 0px;
      display: inherit;
    }

    &.scrolled {
      top: 60px;
    }

    ul {
      position: relative;
      background-color: rgba(255, 255, 255, 0.90);
      padding: 0;
      width: 100%;
      transform: none;

      li {
        padding: 15px 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  
}
</style>
