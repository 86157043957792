<template>
  <div class="home">
    <div class="capa" alt="Casal idoso se abraçando, cuide da sua família com SAFE" src="../assets/cover.jpg">
      <div class="image"></div>
      <h1>Cuide da sua <br/> família com <b>SAFE</b></h1>
    </div>
    
    <div class="beneficios">
      <div class="wrapper pure-g">

        <div class="pure-u-1 pure-u-md-1-3" data-aos="fade-right" data-aos-easing="ease-out-cubic" data-aos-duration="800">
          <div class="wrapper">
            <div class="header">
              <img src="../assets/beneficio-1.svg"/>
              <h3>Clube +<br/>Vantagens</h3>
            </div>
            <p>
              Seguro Safe é + vantagens. Contratando o Seguro Safe, você conta com
              descontos exclusivos em farmácias, clínicas, laboratórios e consultas médicas,
              e até em mercados.
            </p>
          </div>
        </div>

        <div class="pure-u-1 pure-u-md-1-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="800">
          <div class="wrapper">
            <div class="header">
              <img src="../assets/beneficio-2.svg"/>
              <h3>Atendimento<br/>Humanizado</h3>
            </div>
            <p>
              O Safe Mão Amiga se importa de verdade com o sentimento de cada um dos seus clientes.
              Por experiência própria sabemos a importância desse atendimento humanizado, 
              e priorizamos o bem estar em qualquer momento.
            </p>
          </div>
        </div>

        <div class="pure-u-1 pure-u-md-1-3" data-aos="fade-left" data-aos-easing="ease-out-cubic" data-aos-duration="800">
          <div class="wrapper">
            <div class="header">
              <img src="../assets/beneficio-3.svg"/>
              <h3>Segurança e<br/>Tranquilidade</h3>
            </div>
            <p>
              A Safe não cuida de você e sua família apenas em um momento de perda, mas cuida também para que sua família viva bem, e viva mais!
              O Seguro Safe é sinônimo de segurança e tranquilidade.
              Viva o hoje com mais leveza, conte com Safe!
            </p>
          </div>
        </div>

      </div>
    </div>

    <div class="nosso-espaco">
      <h2>Nosso Espaço</h2>
      <vue-picture-swipe :items="images"
        style="text-align: center;"
        />
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import VuePictureSwipe from 'vue-picture-swipe'
import AOS from 'aos'
Vue.component('vue-picture-swipe', VuePictureSwipe);

export default {
  name: 'Home',
  data: () => {
    return {
      windowWidth: Number,
      images: [...Array(14).keys()].map(i => {
        return {
          src: require(`../assets/espaco/${i + 1}.jpg`),
          thumbnail: require(`../assets/espaco/thumbnail/${i + 1}.jpg`),
          w: 1200,
          h: 800,
          alt: "Espaço"
        }
      })
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth < 600
    }
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    addEventListener('resize', () => {
      this.updateWindowWidth()
    })

    AOS.init()
  }
}
</script>

<style lang="scss" scoped>
@import url('https://unpkg.com/aos@2.3.1/dist/aos.css');

.home {
  font-size: 25px;
  font-weight: 200;

  .capa {
    position: relative;

    .image {
      width: 100%;
      height: 75vh;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 35%, 
                      rgba(255, 255, 255, 0.753) 70%), 
                        url("../assets/cover.jpg");
      background-size: cover;
      background-position: right;
    }

    h1 {
      text-align: right;
      right: 10%;
      top: 45%;
      transform: translateY(-50%);
      position: absolute;
      font-weight: 100;
      font-size: 40px;
      line-height: 130%;

      b {
        font-weight: 600;
      }
    }
  }

  .beneficios {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 70px 10px 30px;
    overflow-x: hidden;

    > .wrapper {
      max-width: 900px;

      > div {
        padding: 20px;

        > .wrapper {
          text-align: center;
          border: 1px solid #2c3e50;
          padding: 10px 20px;
          height: 100%;
          
          .header {
            img {
              display: block;
              margin: auto;
              height: 60px;
            }

            h3 {
              font-size: 28px;
              display: inline-block;
              margin: 15px 0 5px;
            }
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }

  .nosso-espaco {
    padding: 20px 30px 40px;
    max-width: 990px;
    margin: auto;
  }

  .nosso-espaco img {
    width: 50px;
  }
}

@media (max-width: 840px) {
  .home {
    .capa {
      .image {
        height: 40vh;
        background-image: url("../assets/cover.jpg");
        background-position: left;
      }

      h1 {
        position: static;
        transform: none;
        padding: 30px 50px 30px 30px;
      }
    }

    .nosso-espaco {
      padding: 20px 8px 40px;
      max-width: 990px;
      margin: auto;
    }
  }
}

@media (max-width: 48em) {
  .home {
    .beneficios {

      > .wrapper {

        > div {
          display: flex;
          justify-content: center;

          > .wrapper {
            max-width: 300px;
          }
        }
      }
    }
  }
}
</style>
