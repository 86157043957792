<template>
  <div id="footer">
    <div class="wrapper pure-g">
      <div class="logo pure-u-1 pure-u-md-6-24">
        <img src="../assets/logo-azul.svg">
      </div>
      <div class="site-map pure-u-1 pure-u-md-4-24">
        <h3>WEBSITE</h3>
        <a href="/sobre">QUEM SOMOS</a> <br/>
        <a href="/contato">CONTATO</a> <br/>
      </div>
      <div class="enderecos pure-u-1 pure-u-md-7-24">
        <h3>ENDEREÇOS</h3>
        <h4>Administrativo</h4>
        <a target="_blank" href="https://goo.gl/maps/qXSLAopUXs2v8u3d9">
          R. Dom Pedro II, 655, Centro, São Mateus do Sul - PR, 83900-000
        </a> <br/>
        <h4>Memorial</h4>
        <a target="_blank" href="https://goo.gl/maps/g5iQUQzRqcMdTVka9">
          R. Eduardo Sprada, 751, Centro, São Mateus do Sul - PR, 83900-000
        </a>
      </div>
      <div class="social pure-u-1 pure-u-md-7-24">
        <h3>REDES SOCIAIS</h3>
        <a target="_blank" href="https://www.facebook.com/SAFEMaoAmigaSantaTerezinha/">
          <img src="../assets/fb.png"/>
          <div>SAFE Mão Amiga</div>
        </a>
        <a target="_blank" href="https://www.instagram.com/safe.maoamiga/">
          <img src="../assets/ig.png"/>
          <div>safe.maoamiga</div>
        </a>
        <a target="_blank" href="https://api.whatsapp.com/send?phone=5542988060260">
          <img src="../assets/wp.png"/>
          <div>Whatsapp</div>
        </a>
      </div>
    </div>
    <div class="info">
      CNPJ: 2.487.701/0001-22
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
#footer {
  height: auto;
  font-size: 16px;
  background: rgb(230, 230, 230);
  padding: 60px 35px 20px 35px;

  .wrapper {

    * {
      box-sizing: border-box;
    }

    .logo {
      display: flex;
      justify-content: center;

      img {
        max-height: 180px;
        align-self: center;
        flex: 0 0 auto;
      }
    }

    .site-map, .enderecos, .social {
      padding-left: 30px;

      a {
        text-decoration: none;
        color: inherit;
      }

      h3 {
        font-weight: 800;
      }

      h4 {
        margin: 10px 0 3px;
      }
    }

    .site-map {
      line-height: 140%;
    }

    .social {
      a {
        display: block;
        margin: 8px;

        img {
          height: 25px;
        }

        div {
          display: inline-block;
          vertical-align: super;
          margin-left: 6px;
        }
      }
    }
  }

  .info {
    width: 100%;
    padding: 50px 0;
    text-align: center;
  }

  @media screen and (max-width: 48em) {
    padding: 30px 10px;
    text-align: center;

    .wrapper {
      .logo {
        padding-bottom: 15px;
      }

      .site-map, .enderecos, .social {
        padding: 15px 20px 0 20px;
      }
    }
  }

}
</style>